import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Swap'),
    icon: 'Swap',
    href: '/swap',
    showItemsOnMobile: true,
    items: [
      {
        label: t('Swap'),
        href: '/swap',
      },
    ],
  },
  {
    label: t('Pool'),
    href: '/pool',
    icon: 'Earn',
    items: [
      {
        label: t('Pool'),
        href: '/pool',
      },
    ],
  },
  
]

export default config
